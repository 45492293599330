import BaseService from "@/services/BaseService";

class ApService extends BaseService {

    constructor(config) {
        super(config);
    }

    /**
     * Delete a specific certificate from specified AP
     * @param payload - apid, certid
     * @returns {Promise<*>}
     */
    deleteCertificate(payload) {
        return this.post("/ap/cert/delete", payload);
    }

    /**
     * Creates a new Applicatin Provider.
     * @param apid
     * @param payload - apname, state, client_certificate(optional), generateApiKey (optional), generatePassword (optional)
     * @returns {Promise<*>}
     */
    createAp(apid, payload) {
        return this.post("/ap/create?apid=" + apid, payload);
    }

    /**
     * Uploads an AP export file for prompts
     * @param payload - file, filename, filetype
     * @returns {Promise<*>}
     */
    uploadDatafile(payload) {
        return this.post("/ap/datafile/upload", payload);
    }

    /**
     * Uploads .csv of AP_ID, AP_NAME, AP_PWD from Valimo systems
     * @param payload - file, filename, filetype
     * @returns {Promise<*>}
     */
    uploadApNamesDatafile(payload) {
        return this.post("/ap/datafile/ap/upload", payload);
    }

    /**
     * Delets Application Provider by AP ID
     * @param apid
     * @returns {Promise<*>}
     */
    deleteAp(apid) {
        return this.post("/ap/delete?apid=" + apid);
    }

    /**
     * Get details of Application Provider by AP ID
     * @param apid
     * @returns {Promise<*>}
     */
    getSingleAp(apid) {
        return this.get("/ap/get?apid=" + apid);
    }

    /**
     * Get all Application Providers that match the query
     * @returns {Promise<*>}
     * @param requestUrl - skipcache, limit, startAt, targetType, targetValue, apid, route
     */
    getApsByQuery(requestUrl) {
        return this.get("/ap/list" + requestUrl);
    }

    /**
     * Provision Application Provider by CSV
     * @param payload
     * @returns {Promise<*>}
     */
    provisionApByCsv(payload) {
        //TODO: This is currently not used in KWM FE
        return this.post("/ap/provision", payload);
    }

    /**
     * Updates Application Provider details from payload
     * @param apid
     * @param payload -
     * @returns {Promise<*>}
     */
    updateAp(apid, payload) {
        return this.post("/ap/update?apid=" + apid, payload);
    }

    /**
     * Get statistics on a singe AP.
     */
    getApStats() {
        return this.get("/stats/ap");
    }

    /**
     * List exported APs for a specific AP
     * @param apid - Target AP ID
     * @returns {Promise<*>}
     */
    listApExports(apid) {
        return this.get("/ap/list/exportedap?apid=" + apid);
    }

    /**
     * Update a specific AP's prompt value
     * Param to edit should be based on MRegParams.SP_PARAM's
     * @param apid
     * @param payload - language, signature_profile, param_to_edit, new_value
     * @returns {Promise<*>}
     */
    updateApExportPrompt(apid, payload) {
        return this.post("/ap/exportedap/update/prompt?apid=" + apid, payload);
    }

    /**
     * Delete a specific AP's prompt value
     *
     * @param apid
     * @param payload - signature_profile, language, param_to_delete
     * @returns {Promise<*>}
     */
    deleteApExportPrompt(apid, payload) {
        return this.post("/ap/exportedap/delete/prompt?apid=" + apid, payload);
    }

    /**
     * Bind a valimo service account to AP profile mapping
     * OMD-59
     * @param payload
     * @returns {Promise<*>}
     */
    bindApService(payload) {
        return this.post("/ap/service/bind", payload);
    }

    /**
     * Unbind a valimo service
     * OMD-59
     * @param payload
     */
    unbindApService(payload) {
        return this.post("/ap/service/unbind", payload);
    }

    /**
     * Create a valimo service account
     * OMD-59
     * @param payload - service_name, ap_sig_prof_uri, ap_sig_prof_name, attributes
     */
    createServiceAccount(payload) {
        return this.post("/ap/service/create", payload);
    }

    /**
     * Delete a valimo service account
     * OMD-59
     * @param id
     * @returns {Promise<*>}
     */
    deleteServiceAccount(id) {
        return this.post("/ap/service/delete/" + id);
    }

    /**
     * List all AP service accounts.
     * OMD-59
     * @param id - optional
     * @returns {Promise<*>}
     */
    listServiceAccounts(id) {
        //return this.get("/ap/service/list");
         let endpoint = "/ap/service/list";
         if (id) endpoint  += "?apid=" + id;
         return this.get(endpoint);
    }

    /**
     * Get a single service account
     * OMD-59
     * @param id
     */
    getServiceAccount(id) {
        return this.get("/ap/service/get/" + id);
    }


    /**
     * Update service account
     * @param payload
     */
    updateServiceAccount(payload) {
        return this.post("/ap/service/update", payload);
    }

}

export default ApService;